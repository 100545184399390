@font-face {
  font-family: "MontserratLight";
  src: url("fonts/MontserratLight.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratBold";
  src: url("fonts/MontserratBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratBook";
  src: url("fonts/MontserratBook.ttf") format("truetype");
  font-display: swap;
}

body {
  background: rgb(253, 253, 253);
  font-family: "MontserratBook", "Verdana";
}

* {
  font-weight: 10;
  margin: 0px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin {
  margin: 20px;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 43px;
}

h3 {
  font-size: 23px;
  font-family: "MontserratLight";
}

p {
  font-size: 15px;
  font-family: "MontserratLight", "Verdana";
}

@media screen and (max-width: 675px) {
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
}

.route-is-active,
.route-is-active-left {
  position: relative;
}

.route-is-active::after {
  content: "";
  width: auto;
  display: block;
  position: absolute;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px 4px 0px 0px;
  background: rgb(193, 61, 123);
}

.route-is-active-left::after {
  content: "";
  height: auto;
  display: block;
  position: absolute;
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 0px 4px 4px 0px;
  background: rgb(27, 117, 188);
}

input,
select {
  display: block;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #bcbcbc;
  padding: 10px 15px;
}
